<template>
  <ion-page><cyan-page-wrapper title="Elegir provincia" :burst="true" backRoute="/home" nav1="DIRECTORIO">
    <mapa-sv nextRoute="/dirPickMpio/" :key="'mapaDirPickDepto'"/>
    <territory-picker nextRoute="/dirPickMpio/" />
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, { defineComponent, IonPage } from '@/components/CyanPageWrapper.vue'
import TerritoryPicker from '@/components/TerritoryPicker.vue';
import MapaSv from '@/components/MapaSv.vue';

export default defineComponent({
  name: "DirPickDepto",
  components: {
    TerritoryPicker,
    CyanPageWrapper,
    IonPage,
    MapaSv
  },
  computed: {},
  methods: {}
});
</script>
